<template>
  <div>
    <location />

    <div class="p44 page">
      <div class="wrap-1200">
        <b-form @submit.prevent="onSubmit">
          <div class="row g-0">
            <div class="col">
              <h3 class="subtitle">코스 목록</h3>
            </div>
            <div class="col-lg-4 m-0">
              <div class="gender-search">
                <select
                  class=""
                  aria-label=".form-select-lg example"
                  name="countryno"
                  v-model="searchData.countryno"
                >
                  <option value="">전체</option>
                  <option value="1">미국</option>
                  <option value="2">호주</option>
                  <option value="3">중국</option>
                  <option value="4">일본</option>
                  <option value="5">한국</option>
                  <option value="6">캐나다</option>
                  <option value="7">영국</option>
                  <option value="8">프랑스</option>
                  <option value="9">기타</option>
                </select>
                <!-- <input
                  class="btn btn-primary btn-lg search"
                  type="submit"
                  value=""
                /> -->
              </div>
            </div>
          </div>
        </b-form>

        <div class="row g-0" v-if="data_yn">
          <div
            class="col-lg-4 border-top-dgray"
            :key="index"
            v-for="(item, index) in items"
          >
            <a
              class="more text-truncate"
              @click="
                $router.push({
                  name: 'CourseView',
                  params: { id: item.courseno },
                })
              "
              >[{{ item.area }}] {{ item.coursename }}</a
            >
            <div class="border-bottom">
              <router-link
                :to="{
                  name: 'CourseView',
                  params: { id: item.courseno },
                  query: {
                    countryno: searchData.countryno,
                  },
                }"
                class="position-relative view"
              >
                <img :src="(item.path + '/' + item.image) | get_img" />
                <div class="mask"></div>
                <span class="icon-view"></span>
              </router-link>

              <div>
                <div class="row g-0 border-bottom">
                  <div class="col-6 bg-gray row g-0 align-items-center">
                    <label
                      class="align-center text-black font-weight-bold d-table-cell"
                      >코스난이도</label
                    >
                  </div>
                  <div class="col-6 d-table">
                    <p class="d-table-cell">
                      <i
                        class="material-icons-outlined"
                        :class="
                          item.field >= index ? 'text-yellow' : 'text-lightgray'
                        "
                        v-for="index in 5"
                        :key="index"
                        >star</i
                      >
                    </p>
                  </div>
                </div>
                <div class="row g-0 border-bottom">
                  <div class="col-6 bg-gray row g-0 align-items-center">
                    <label
                      class="align-center text-black font-weight-bold d-table-cell"
                      >그린난이도</label
                    >
                  </div>
                  <div class="col-6 d-table">
                    <p class="d-table-cell">
                      <i
                        class="material-icons"
                        :class="
                          item.green >= index ? 'text-green' : 'text-lightgray'
                        "
                        v-for="index in 5"
                        :key="index"
                        >flag</i
                      >
                    </p>
                  </div>
                </div>
                <div class="row g-0 border-bottom">
                  <div class="col-6 bg-gray row g-0 align-items-center">
                    <label
                      class="align-center text-black font-weight-bold d-table-cell"
                      >홀 / 파</label
                    >
                  </div>
                  <div class="col-6 d-table">
                    <p class="d-table-cell">
                      {{ item.hole + " 홀 " + "/" + " 파 " + item.par }}
                    </p>
                  </div>
                </div>
                <div class="row g-0 border-bottom-0">
                  <div class="col-6 bg-gray row g-0 align-items-center">
                    <label
                      class="align-center text-black font-weight-bold d-table-cell"
                      >거리</label
                    >
                  </div>
                  <div class="col-6 d-table">
                    <p class="d-table-cell">{{ item.distance | comma }} m</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-0 border-top-dgray border-bottom text-center" v-else>
          <div class="col-lg-12 lh-56">데이터가 없습니다.</div>
        </div>

        <b-pagination-nav
          :link-gen="linkGen"
          :number-of-pages="page_total"
          v-model="searchData.pageno"
          use-router
          aria-label="Page navigation example"
          v-if="page_total > 1"
          first-class="prev"
          prev-class="prev"
          next-class="next"
          last-class="next"
          hide-ellipsis
          limit="10"
        >
          <template #first-text>
            <i class="material-icons">keyboard_double_arrow_left</i>
          </template>
          <template #prev-text>
            <i class="material-icons">navigate_before</i>
          </template>
          <template #next-text>
            <i class="material-icons">navigate_next</i>
          </template>
          <template #last-text>
            <i class="material-icons">keyboard_double_arrow_right</i>
          </template>
          <template #page="{ page, active }">
            <a v-if="active">{{ page }}</a>
            <a v-else>{{ page }}</a>
          </template>
        </b-pagination-nav>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/golfcourse";
import "@/filter/common";

export default {
  name: "CourseList",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        countryno: this.$route.query.countryno || "",
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
    };
  },
  watch: {
    "$route.query": function () {
      this.searchData.pageno = parseInt(this.$route.query.page) || 1;
      this.get_courselist();
    },
    "searchData.countryno": function () {
      this.onSubmit();
    },
  },
  methods: {
    get_courselist() {
      ax.get_courselist(this.searchData, (flag, data) => {
        if (flag) {
          if (data.courselistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }
          this.total_cnt = data.courselistcnt;
          this.page_total = Math.ceil(data.courselistcnt / this.rows);
          this.items = data.courselist;
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    onSubmit(event) {
      // event.preventDefault();
      this.searchData.pageno = 1;
      this.$router.push({
        query: {
          countryno: this.searchData.countryno,
        },
      });
    },
    linkGen(pageNum) {
      return pageNum === 1
        ? `?countryno=${this.searchData.countryno}`
        : `?page=${pageNum}&countryno=${this.searchData.countryno}`;
    },
    cc_img(index) {
      if (this.items[index].image !== null)
        return this.items[index].path + "/" + this.items[index].image;
      else return "/img/noccimage.jpg";
    },
  },
  created() {
    this.get_courselist();
  },
};
</script>
