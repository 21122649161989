<template>
  <div>
    <location />

    <div class="p45 page">
      <div class="wrap-1200">
        <h3 class="subtitle">코스 정보</h3>
        <div class="row g-0">
          <h5 class="border-top-dgray border-bottom">
            [{{ basicinfo.area }}] {{ basicinfo.coursename }}
          </h5>
          <div class="row g-0 border-bottom">
            <div class="col-lg-6">
              <img
                :src="(basicinfo.path + '/' + basicinfo.image) | get_img"
                style=""
              />
            </div>
            <div class="col-lg-6">
              <div class="row g-0 border-bottom in">
                <div class="col-lg bg-gray row g-0 align-items-center">
                  <label class="d-table-cell align-center font-bold">
                    코스난이도
                  </label>
                </div>
                <div class="col-lg">
                  <p class="d-table-cell">
                    <i
                      class="material-icons-outlined"
                      :class="
                        basicinfo.field >= index
                          ? 'text-yellow'
                          : 'text-lightgray'
                      "
                      v-for="index in 5"
                      :key="index"
                    >
                      star
                    </i>
                  </p>
                </div>
              </div>
              <div class="row g-0 border-bottom in">
                <div class="col-lg bg-gray row g-0 align-items-center">
                  <label class="d-table-cell align-center font-bold">
                    그린난이도
                  </label>
                </div>
                <div class="col-lg">
                  <p class="d-table-cell">
                    <i
                      class="material-icons"
                      :class="
                        basicinfo.green >= index
                          ? 'text-green'
                          : 'text-lightgray'
                      "
                      v-for="index in 5"
                      :key="index"
                    >
                      flag
                    </i>
                  </p>
                </div>
              </div>
              <div class="row g-0 border-bottom in">
                <div class="col-lg bg-gray row g-0 align-items-center">
                  <label class="d-table-cell align-center font-bold"
                    >홀 / 파</label
                  >
                </div>
                <div class="col-lg">
                  <p class="d-table-cell">
                    {{
                      basicinfo.holesum +
                      " 홀 " +
                      "/" +
                      " 파 " +
                      basicinfo.parsum
                    }}
                  </p>
                </div>
              </div>
              <div class="row g-0 in">
                <div class="col-lg bg-gray row g-0 align-items-center">
                  <label class="d-table-cell align-center font-bold"
                    >거리</label
                  >
                </div>
                <div class="col-lg">
                  <p class="d-table-cell">{{ basicinfo.distance | comma }} m</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p class="pt-4">
          <span class="d-inline-block icon_alarm">
            각 홀에 대해 자세히 알아보려면 아래의
            <span class="text-orange">홀 또는 파 번호를 클릭</span>하십시오.
          </span>
        </p>

        <div
          v-for="(anc, index) in subcourse"
          :key="index"
          class="course-table"
        >
          <p class="info">서브 코스 {{ anc.subcourseseq }} [{{ anc.title }}]</p>
          <div class="position-relative">
            <table class="table scorecard_title" width="100%" cellspacing="0">
              <thead>
                <tr class="bg-gray">
                  <th>홀</th>
                </tr>
                <tr class="bg-gray">
                  <th class="border-0">파</th>
                </tr>
              </thead>
            </table>
            <div
              class="courselist view list"
              v-if="anc.subcoursehole.length > 0"
            >
              <div class="in overflow-auto fix-table">
                <table class="table" width="100%" cellspacing="0">
                  <tbody>
                    <tr>
                      <td
                        v-for="(inc, index) in anc.subcoursehole"
                        :key="index"
                        @click="showModal(inc.hole_no, anc.subcourseseq)"
                      >
                        <span class="rounded-circle info-hole">{{
                          inc.hole_no
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td
                        v-for="(inc, index) in anc.subcoursehole"
                        :key="index"
                        @click="showModal(inc.hole_no, anc.subcourseseq)"
                      >
                        <span class="rounded-circle info-par">{{
                          inc.par_score
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

<!--         <div class="start-m row g-0 center-btn">
          <div class="col-lg-4 d-table m-auto">
            <button
              class="btn btn-primary btn-xl"
              type="button"
              @click="
                $router.push({
                  name: 'CourseList',
                  query: {
                    countryno: $route.query.countryno,
                  },
                })
              "
            >
              목록
            </button>
          </div>
        </div> -->
        <div class="btn-bottom-wrapper">
        <b-button size="xl" variant="outline-primary" aria-label="목록" @click="
                $router.push({
                  name: 'CourseList',
                  query: {
                    countryno: $route.query.countryno,
                  },
                })
              ">목록</b-button>
      </div>
<!--         <template #modal-footer="{}">
        <b-button size="md" variant="outline-secondary" aria-label="목록" @click="
                $router.push({
                  name: 'CourseList',
                  query: {
                    countryno: $route.query.countryno,
                  },
                })
              "
          >목록</b-button
        >
        </template> -->
      </div>
    </div>

    <b-modal id="exampleModal" ref="my-modal" hide-footer no-fade>
      <template #modal-header>
        <h3 class="Montserrat">
          서브코스 {{ hole_model.coursubtype }} ({{ hole_model.subcoursename }})
          {{ hole_model.hole }} 홀<!--  (파 {{ hole_model.par }}) -->
        </h3>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="hideModal"
        >
          <i class="material-icons">close</i>
        </button>
      </template>
      <div class="left">
        <p>코스 미니맵</p>
        <img
          :src="(hole_model.fieldpath + '/' + hole_model.fieldmap) | get_img"
        />
      </div>
      <div class="right">
        <p class="r" v-if="hole_model.greenmapr != null">그린 미니맵 (우)</p>
        <img
          :src="(hole_model.greenpathr + '/' + hole_model.greenmapr) | get_img"
          v-if="hole_model.greenmapr != null"
        />
        <p v-if="hole_model.greenmapl != null">그린 미니맵 (좌)</p>
        <img
          :src="(hole_model.greenpathl + '/' + hole_model.greenmapl) | get_img"
          v-if="hole_model.greenmapl != null"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/golfcourse";
import "@/filter/common";

export default {
  name: "CourseView",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        courseno: this.$route.params.id || "",
      },
      basicinfo: [
        {
          area: "",
          coursename: "",
          path: "",
          image: "",
          field: "",
          green: "",
          hole: "",
          par: "",
          distance: "",
        },
      ],

      subcourse: [
        {
          subcourseseq: "",
          subcourseno: "",
          subcoursename: "",
          subcoursehole: [],
        },
      ],
      hole_model: {
        hole: "",
        coursubtype: "",
        subcoursename: "",
        par: "",
        fieldpath: "",
        fieldmap: "",
        greenpathl: "",
        greenmapl: "",
        greenpathr: "",
        greenmapr: "",
      },
      subcourselist: [],
      subcourselistcnt: 0,
      scroll: "r",
      hold: false,
      inter_clear: Object,
    };
  },
  methods: {
    get_courseview() {
      ax.get_courseview(this.searchData, (flag, data) => {
        if (flag) {
          this.basicinfo = data.basicinfo;

          this.subcourse = data.subcourse || [];
        } else {
          alert(data);
        }
      });
    },
    showModal(hole, type) {
      ax.get_courseholeview(
        this.searchData.courseno,
        hole,
        type,
        (flag, data) => {
          if (flag) {
            this.hole_model.hole = hole;
            this.hole_model.coursubtype = type;
            this.hole_model.subcoursename = data.courseholeview.subcoursename;
            this.hole_model.par = data.courseholeview.par;
            this.hole_model.fieldpath = data.courseholeview.fieldpath;
            this.hole_model.fieldmap = data.courseholeview.fieldmap;
            this.hole_model.greenpathl = data.courseholeview.greenpathl;
            this.hole_model.greenmapl = data.courseholeview.greenmapl;
            this.hole_model.greenpathr = data.courseholeview.greenpathr;
            this.hole_model.greenmapr = data.courseholeview.greenmapr;
            this.$refs["my-modal"].modalOuterStyle.zIndex = 9999;
            this.$refs["my-modal"].show();
          } else {
            alert(data);
          }
        }
      );
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    cc_img() {
      if (this.basicinfo.image !== null)
        return this.basicinfo.path + "/" + this.basicinfo.image;
      else return "/img/noccimage.jpg";
    },
    scroll_x() {
      let content = document.querySelector(".list");
      let scrollAmount = 0;
      if (this.scroll === "r") {
        //content.scrollLeft += 40
        scrollAmount = 0;
        let slideTimer = setInterval(function () {
          content.scrollLeft += 40;
          scrollAmount += 40;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 25);
      } else {
        //content.scrollLeft -= 40
        scrollAmount = 0;
        let slideTimer = setInterval(function () {
          content.scrollLeft -= 40;
          scrollAmount += 40;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 25);
      }
    },
    course_click(direction) {
      let scrollAmount = 0;
      let content = document.querySelector(".list");
      if (direction === "r") {
        scrollAmount = 0;
        let slideTimer = setInterval(function () {
          content.scrollLeft += 40;
          scrollAmount += 40;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 25);
      } else {
        scrollAmount = 0;
        let slideTimer = setInterval(function () {
          content.scrollLeft -= 40;
          scrollAmount += 40;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 25);
      }
    },
  },
  created() {
    this.get_courseview();
  },
  watch: {
    hold: function () {
      if (this.hold) {
        this.inter_clear = setInterval(this.scroll_x, 1000);
      } else {
        clearInterval(this.inter_clear);
      }
    },
  },
};
</script>
<style scoped>
.scorecard_title {
  width: 200px;
  position: absolute;
  top: 0;
  z-index: 10;
}

#sub .course-table .fix-table {
  width: calc(100% - 200px);
  margin-left: 200px;
}

#sub .course-table .fix-table .table {
  width: 100%;
}

@media (max-width: 1199.98px) {
  .scorecard_title {
    width: 100px;
  }
  #sub .course-table .fix-table {
    margin-left: 100px;
    width: calc(100% - 100px);
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  #sub .course-table .fix-table {
    width: calc(100% - 100px);
    margin-left: 100px;
  }
}

@media (max-width: 991.98px) {
  #sub .course-table .fix-table {
    width: calc(100% - 100px);
    margin-left: 100px;
  }
  #sub .course-table .fix-table .table {
    width: 100%;
  }
}
</style>
