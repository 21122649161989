import axios from "@/api/axios";
import store from "@/store";
import _ from "lodash";

// 골프코스 리스트 가지고 오기
const get_courselist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    courselistcnt: 0,
    courselist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/course/courselist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: {
      countryno: param.countryno,
      pageno: param.pageno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_courselist", err);
    result(false, err);
  }
};

// 코스상세보기
const get_courseview = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    basicinfo: null,
    subcoursecnt: "",
    subcourse: [
      {
        subcourseseq: "",
        subcourseno: "",
        subcoursename: "",
        subcoursehole: [],
      },
    ],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/course/courseview/" + param.courseno,
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_courseview", err);
    result(false, err);
  }
};

// 코스홀보기
const get_courseholeview = async (courseno, holeno, coursubtype, result) => {
  let finalData = {
    code: "",
    message: "",
    courseholeview: [
      {
        subcoursename: "",
        par: "",
        fieldpath: "",
        fieldmap: "",
        greenpathl: "",
        greenmapl: "",
        greenpathr: "",
        greenmapr: "",
      },
    ],
  };

  const options = {
    method: "GET",
    url:
      process.env.VUE_APP_API_URL +
      "/course/courseholeview/" +
      courseno +
      "/" +
      coursubtype +
      "/" +
      holeno,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_courseholeview", err);
    result(false, err);
  }
};

export default {
  get_courselist,
  get_courseview,
  get_courseholeview,
};
